@import "definitions";

.center {
  margin-left: auto;
  margin-right: auto;
  padding-left: $padding-content;
  padding-right: $padding-content;

  .center {
    padding-left: 0;
    padding-right: 0;
  }
}

.oneColumn {
  max-width: $width-content;
}

.twoColumn {
  max-width: $width-content-2column;
}

.columns {
  display: grid;

  @include above-small {
    grid-template-columns: 1fr 1fr;
    column-gap: 2rem;
  }

  @include below-small {
    grid-template-columns: 1fr;
    row-gap: 2rem;
  }
}

.flex {
  display: flex;
  align-items: center;
  justify-content: center;

  @include above-small {
    > * {
      flex-grow: 1;
      flex-shrink: 0;
      flex-basis: calc(50% - 0.5rem);
      // HACK: For selects...
      min-width: auto !important;
    }
  }

  > *:not(:last-child) {
    margin-right: 1rem;
  }
}
